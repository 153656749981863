import React, { useEffect, useState } from 'react';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';
import EditIcon from '../../assets/icons/Edit_icon.svg';
import { Form, Modal } from 'react-bootstrap';
import BackButton from '../../assets/icons/Back_Arrow_Left_icon.svg';
import CircleButton from '../../assets/icons/Circle_icon.svg';
import { useRecoilState } from 'recoil';
import {
    editBackupPowerAtom,
    modeAtom,
    rangeReserveAtom,
    showSideMenuAtom,
    vehicleStateAtom
} from '../../atoms/vehicleState.js';
import './EditRangeReserve.scss';
import PrivacyLinks from '../PrivacyLinks/PrivacyLinks.jsx';
import SaveButton from '../SaveButton/SaveButton.jsx';
import ConfirmationOfExitWithoutSaving from '../ConfirmationOfExitWithoutSaving/ConfirmationOfExitWithoutSaving.jsx';
import ERROR_STATUS from '../../constants/ErrorStatusConst.js';
import { useNavigate } from 'react-router-dom';

export default function EditRangeReserve({
    isUpdateInProgress,
    updateBackupPower,
    isHomePageFlow
}) {
    const [showEditRangeReserve, setShowEditRangeReserve] = useState(false);
    const [vehicleState] = useRecoilState(vehicleStateAtom);
    const [, setShowSideMenu] = useRecoilState(showSideMenuAtom);
    const [, setNewRangeReserveValue] = useRecoilState(rangeReserveAtom);
    const [, setNewModeValue] = useRecoilState(modeAtom);
    const [, setEditBackupPowerInProgress] = useRecoilState(editBackupPowerAtom);
    const [rangeReserve, setRangeReserve] = useState(vehicleState.reserve);
    const vehicleStateMode = vehicleState.mode === 'AUTO';
    const [automaticTransfer, setAutomaticTransfer] = useState(vehicleStateMode);
    const currentRange = vehicleState.currentRange;
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [showLeaveWithChangesConfirmation, setShowLeaveWithChangesConfirmation] = useState(false);
    const navigate = useNavigate();

    const postEditRangeReserve = () => {
        const mode = automaticTransfer ? 'AUTO' : 'MANUAL';
        if (!isSaveButtonDisabled) {
            setEditBackupPowerInProgress(true);
            setNewRangeReserveValue(rangeReserve);
            setNewModeValue(mode);
            updateBackupPower(rangeReserve, mode);
        }
    };

    const handleSliderChange = (e) => {
        trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_SLIDER_CHANGED);
        setRangeReserve(e.target.value);
    };

    const updateSliderValue = (value) => {
        const slider = document.querySelector('input[type="range"]');
        if (slider) {
            const percentage = ((value - 10) / (currentRange - 10)) * 100;
            slider.style.setProperty('--value', `${percentage}%`);
        }
    };

    const handlePlusButtonClick = () => {
        const newRangeReserve = Number(rangeReserve) + 1;
        setRangeReserve(newRangeReserve);
    };

    const handleMinusButtonClick = () => {
        const newRangeReserve = Number(rangeReserve) - 1;
        setRangeReserve(newRangeReserve);
    };

    const handleSaveButtonState = () => {
        setIsSaveButtonDisabled(
            rangeReserve === vehicleState.reserve && automaticTransfer === vehicleStateMode
        );
    };

    useEffect(() => {
        updateSliderValue(rangeReserve);
        handleSaveButtonState();
    }, [rangeReserve]);

    useEffect(() => {
        handleSaveButtonState();
    }, [automaticTransfer]);

    useEffect(() => {
        if (showEditRangeReserve && currentRange == null) {
            navigate('/error', { state: ERROR_STATUS.Unexpected });
        } else {
            if (showEditRangeReserve) {
                setIsSaveButtonDisabled(true);
            }
        }
        updateSliderValue(rangeReserve);
    }, [showEditRangeReserve]);

    return (
        <div>
            {isHomePageFlow ? (
                <button
                    className="edit-button"
                    id="edit-button-home-page"
                    style={{
                        visibility: isUpdateInProgress ? 'hidden' : 'visible',
                        pointerEvents: isUpdateInProgress ? 'none' : 'auto'
                    }}
                    onClick={() => {
                        trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE);
                        setShowEditRangeReserve(true);
                    }}
                >
                    <img src={EditIcon} alt="Edit Range Reserve" />
                </button>
            ) : (
                <button
                    className="edit-button-side-menu"
                    id="backup-power-side-menu-edit-button"
                    disabled={isUpdateInProgress}
                    onClick={() => {
                        trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_SIDE_MENU);
                        setShowEditRangeReserve(true);
                    }}
                >
                    Edit Range Reserve
                </button>
            )}

            <Modal
                show={showEditRangeReserve}
                animation={true}
                centered
                className="edit-range-reserve-modal"
            >
                <Modal.Body>
                    <div className="scrollable-content">
                        <div className="edit-range-reserve">
                            <div className="edit-range-reserve-header">
                                <button
                                    className="arrow-button-back"
                                    id="arrow-button-back"
                                    onClick={() => {
                                        trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_BACK);
                                        if (!isSaveButtonDisabled) {
                                            setShowLeaveWithChangesConfirmation(true);
                                        } else {
                                            setShowEditRangeReserve(false);
                                            setShowSideMenu(false);
                                        }
                                    }}
                                >
                                    <img src={BackButton} alt="Back Button" />
                                </button>
                                <p
                                    className="edit-range-reserve-header-title"
                                    id="edit-range-reserve-header-title"
                                >
                                    Range Reserve
                                </p>
                                <div className="edit-range-reserve-header-text">
                                    <p>
                                        Transfers will stop when your vehicle reaches your
                                        selection. We recommend setting your energy reserve so that
                                        your vehicle has enough miles remaining to drive to a nearby
                                        charging station
                                    </p>
                                </div>
                            </div>
                            <div className="edit-range-reserve-content">
                                <div className="edit-range-reserve-selector">
                                    {rangeReserve > 10 && (
                                        <button
                                            className="range-reserve-minus"
                                            id="range-reserve-minus"
                                            onClick={() => {
                                                trackEvent(
                                                    AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_PLUS
                                                );
                                                handleMinusButtonClick();
                                            }}
                                        >
                                            <img src={CircleButton} alt="Minus Button" />
                                        </button>
                                    )}
                                    <p> {rangeReserve} mi</p>
                                    {rangeReserve < currentRange && (
                                        <button
                                            className="range-reserve-plus"
                                            id="range-reserve-plus"
                                            onClick={() => {
                                                trackEvent(
                                                    AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_MINUS
                                                );
                                                handlePlusButtonClick();
                                            }}
                                        >
                                            <img src={CircleButton} alt="Plus Button" />
                                        </button>
                                    )}
                                </div>
                                <div className="edit-range-reserve-slider">
                                    <p className="edit-range-reserve-slider-start-label">10 mi</p>
                                    <Form.Range
                                        value={rangeReserve}
                                        min={10}
                                        max={currentRange}
                                        onChange={handleSliderChange}
                                    />
                                    <p className="edit-range-reserve-slider-end-label">
                                        {currentRange} mi
                                    </p>
                                </div>
                            </div>
                            <div className="edit-mode-body">
                                <div className="edit-mode-toggle-container">
                                    <span>Automatic Transfer</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            data-testid="edit-mode-toggle"
                                            checked={automaticTransfer}
                                            onChange={() => {
                                                trackEvent(
                                                    AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_EDIT_MODE_TOGGLE,
                                                    {
                                                        Timestamp: new Date().toISOString(),
                                                        toggle: !automaticTransfer
                                                    }
                                                );
                                                setAutomaticTransfer(!automaticTransfer);
                                            }}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="edit-mode-text">
                                    <p>
                                        Turn Automatic Transfer on or off to determine if your
                                        vehicle will transfer Backup Power automatically (as soon as
                                        as outage is detected) or manually (only when you say so)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SaveButton
                        isSaveButtonDisabled={isSaveButtonDisabled}
                        onClick={() => {
                            trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_SAVE);
                            postEditRangeReserve();
                            setShowEditRangeReserve(false);
                            setShowSideMenu(false);
                        }}
                        id="range-reserve-save-button"
                    />
                    <PrivacyLinks data />
                </Modal.Body>
            </Modal>
            <ConfirmationOfExitWithoutSaving
                showLeaveWithChangesConfirmation={showLeaveWithChangesConfirmation}
                onYes={() => {
                    trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_BACK_YES);
                    setShowLeaveWithChangesConfirmation(false);
                    setShowEditRangeReserve(false);
                    setShowSideMenu(false);
                }}
                onNo={() => {
                    trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_BACK_NO);
                    setShowLeaveWithChangesConfirmation(false);
                }}
            />
        </div>
    );
}
