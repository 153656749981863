import config from '../../env/environment.js';

export const editBackupPowerPost = (VIN, token, rangeReserve, mode, showOrHideDialog) => {
    let requestBody = JSON.stringify({
        mode: mode,
        rangeReserve: rangeReserve
    });

    fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v2/edit-range-reserve`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Auth-Token': token,
            'Content-Type': 'application/json',
            vin: VIN
        },
        body: requestBody
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(response);
            }
        })
        .catch((error) => {
            showOrHideDialog(true);
        });
};
