import UserType from '../../constants/UserTypeConst.js';
import EnergySchedule from '../EnergySchedule/EnergySchedule.jsx';
import WeeklyTransferGoal from '../WeeklyTransferGoal/WeeklyTransferGoal.jsx';
import React, { useEffect, useState } from 'react';
import config from '../../env/environment.js';
import SCHEDULE_STATUS from '../../constants/ScheduleStatusConst.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    energyScheduleAtom,
    fetchLoadingAtom,
    vehicleStateAtom
} from '../../atoms/vehicleState.js';

export default function ChargeInformation({ userType }) {
    const VIN = sessionStorage.getItem('VIN');
    const [energySchedule, setEnergySchedule] = useRecoilState(energyScheduleAtom);
    const [scheduleCalculated, setScheduleCalculated] = useState(false);
    const loading = useRecoilValue(fetchLoadingAtom);
    const vehicleState = useRecoilValue(vehicleStateAtom);

    const getEnergySchedule = () => {
        fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v2/schedule`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken'), vin: VIN }
        })
            .then((response) => response.json())
            .then((data) => {
                setEnergySchedule(data);
                if (data.state === SCHEDULE_STATUS.COMPUTED) {
                    setScheduleCalculated(true);
                }
            })
            .catch((error) => {
                console.error('Error getting Energy Schedule ', error);
            });
    };

    useEffect(() => {
        getEnergySchedule();
        const interval = setInterval(() => {
            getEnergySchedule();
        }, 20000); // calls every 20 seconds
        return () => clearInterval(interval);
    }, []);
    if (!loading) {
        return (
            <>
                {userType !== UserType.HOME_BACKUP_POWER && (
                    <EnergySchedule
                        data-testid="energy-schedule-component"
                        schedule={energySchedule}
                        scheduleCalculated={scheduleCalculated}
                        setScheduleCalculated={setScheduleCalculated}
                        userType={userType}
                    />
                )}
                {userType === UserType.HOME_POWER_MANAGEMENT && !vehicleState.powerOutage && (
                    <WeeklyTransferGoal
                        data-testid="weekly-transfer-goal-component"
                        schedule={energySchedule}
                    />
                )}
            </>
        );
    }
}
